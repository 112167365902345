<article class="bg-white shadow-md p-8">
  <h1 class="text-3xl font-semibold mb-4">{{blog.title}}</h1>
  <p class="text-gray-600 mb-4">{{blog.created_at}}</p>
  <img loading="lazy" src="{{blog.image}}" alt="Blog Post Image" class="w-full h-auto mb-6 rounded-lg object-cover">
  <div class="container mx-auto">
    <div class="ql-container ql-snow" style="border-width: 0;">
      <quill-view-html [content]="blog.content"></quill-view-html>
    </div>
  </div>
</article>
