import { Component } from '@angular/core';
import { NavbarComponent } from '../component/navbar/navbar.component';
import { ProductService } from '../services/product.service';
import { ProductModel } from '../models/productModel.model';
import { NgForOf } from '@angular/common';
import {SeoService} from "../services/seo.service";

@Component({
  standalone: true,
  imports: [NavbarComponent, NgForOf],
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {

  listProduct: ProductModel[] = []
  constructor(
    private productService: ProductService,
    private seo: SeoService
  ) {
    this.seo.generateTags({
      title: 'Products Page',
      description: 'VN hair extensions factory.  Anbi is a full-cycle hair factory that starts with the purchase of donor hair and extensions. ',
      slug: 'products'
    })
    this.getAllProduct()
  }

  getAllProduct(): void {
    this.productService.getAllPost().subscribe((res: any) => {
      if (res.success) {
        this.listProduct = res.data
      }
    })
  }
}
