<div class="min-h-fit flex flex-row h-full homepage">
  <app-navbar></app-navbar>
  <div class="w-full p-8">
    <h1 class="border-l border-l-amber-300 border-l-[6px] pl-3 text-2xl">Blog</h1>
    <a [routerLink]="['/admin/blogs/create']" class="border px-5 py-2 mb-8">Create</a>
    <div *ngFor="let post of listBlog" class="bg-white mb-8 shadow-md mt-8 rounded-lg overflow-hidden flex flex-row pr-4">
        <a [routerLink]="['/admin/blog', post.id]" class="w-48 h-48">
          <img *ngIf="post.image" src="{{post.image}}" loading="lazy" alt="Instagram Image" class="max-w-[unset] w-[inherit] h-full object-cover">
          <img *ngIf="!post.image"src="assets/images/blank-image.jpg" alt="No Image" class="max-w-[unset] h-full object-cover">
        </a>
        <div class="p-4">
          <h2 class="text-lg font-semibold mb-2">
            <a [routerLink]="['/admin/blog', post.id]" class="text-black">{{post.title}}</a>
          </h2>
          <p class="text-gray-700" [innerHTML]="post.short_description"></p>
        </div>
        <mat-slide-toggle [(ngModel)]="post.toggleValue" (ngModelChange)="changeStatus(post.id, post.toggleValue)" class="ml-auto pt-5"></mat-slide-toggle>
      </div>
  </div>
</div>
