import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';
import { UserModel } from '../models/userModel.model';
import { environment } from '../../environments/environment';
import { data } from 'autoprefixer';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  protected apiServerPaths = environment.apiServer.paths.auth;
  constructor(
    private apiService: ApiService
  ) { }

  public login(data: UserModel): Observable<UserModel[]> {
    return this.apiService.post(this.apiServerPaths.login, data, {}, map(response => {
      return response;
    }));
  }
}
