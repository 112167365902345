import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NavbarComponent } from '../../component/navbar/navbar.component';
import { BlogModel } from '../../models/blogModel.model';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BlogService } from '../../services/blog.service';
import {Router} from "@angular/router";
import {UploadService} from "../../services/upload.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {QuillModule} from "ngx-quill";

@Component({
  standalone: true,
  imports: [
    NavbarComponent,
    FormsModule,
    NgIf,
    QuillModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule
  ],
  selector: 'app-blog-admin-creat',
  templateUrl: './blog-admin-creat.component.html',
  styleUrls: ['./blog-admin-creat.component.scss']
})
export class BlogAdminCreatComponent {

  post: BlogModel = {
    content: '',
    created_at: '',
    id: 0,
    image: '',
    is_display: 0,
    short_description: '',
    tags_id: '',
    title: '',
    toggleValue: false,
    user_id: ''
  }
  isLoading = false;

  fileName = '';
  @Input()
  requiredFileType: string = '';

  toggleValue = false;

  constructor(
    private blogService: BlogService,
    private http: HttpClient,
    private router: Router,
    private uploadService: UploadService,
  ) {
  }

  onFileSelected(event: any) {
    this.isLoading = true;
    const file:File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);
      this.uploadService.uploadImageToDrive(formData).subscribe((res: any) => {
        this.post.image = res.imageUrl;
        this.isLoading = false;
      })
    }
  }

  submitForm(): void {
    if (this.toggleValue) {
      this.post.is_display = 1;
    } else {
      this.post.is_display = 0;
    }
    this.post.user_id = '1', this.post.tags_id = '1'
    this.blogService.createBlog(this.post).subscribe((res: any) => {
      if(res.success) {
        this.router.navigate(['/admin/blog']);
      }
    },
    (error) => {
      if (error.status === 413) {
        // Xử lý thông báo lỗi khi mã lỗi HTTP là 413 (Payload Too Large)
        console.error('Payload Too Large Error:', error);
        // Hiển thị thông báo cho người dùng hoặc thực hiện các xử lý khác tùy theo yêu cầu
        window.alert('Image size to large')
      } else {
        // Xử lý các lỗi khác
        console.error('Other Error:', error);
        // Hiển thị thông báo cho người dùng hoặc thực hiện các xử lý khác tùy theo yêu cầu
      }
      this.isLoading = false;
    });
  }
}
