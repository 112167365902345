export const environment = {
  production: false,
  apiUrl: 'https://api.anbihair.com:3000',
  locales: [
    'en'
  ],
  localeDefault: 'en',
  apiServer: {
    host: 'api.anbihair.com',
    ssl: false,
    port: '3000',
    prefix: 'api',
    url: 'http://api.anbihair.com',
    paths: {
      user: {
        list: 'users',
      },
      auth: {
        login: 'login'
      },
      instagrams: {
        list: 'instagrams',
        create: 'instagram/create',
        info: 'social/instagram'
      },
      tiktok: {
        list: 'tiktoks',
        create: 'tiktok/create'
      },
      youtube: {
        list: 'youtubes',
        create: 'youtubes/create'
      },
      product: {
        list: 'products'
      },
      blog: {
        list: 'blogs',
        create: 'blog/create'
      },
      upload: {
        image: 'upload'
      }
    }
  }
};
