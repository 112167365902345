import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private meta: Meta
  ) { }

  generateTags(config: any) {
    // default values
    config = {
      title: 'Anbi Hair',
      description: 'VN hair extensions factory.  Anbi is a full-cycle hair factory that starts with the purchase of donor hair and extensions. ',
      slug: '',
      ...config
    }

    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: 'ANBIHAIR' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:url', content: `http://anbihair.com/${config.slug}` });
  }
}
