import { Injectable } from '@angular/core';
import { ProductModel } from '../models/productModel.model';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  protected apiServerPaths = environment.apiServer.paths.product;
  constructor(
    private apiService: ApiService
  ) { }

  public getAllPost(): Observable<ProductModel[]> {
    return this.apiService.get(this.apiServerPaths.list, {}, map(response => {
      return response;
    }));
  }

  public getPostById(id: number): Observable<ProductModel> {
    return this.apiService.get(this.apiServerPaths.list + '/' + id, {}, map(response => {
      return response;
    }));
  }
}
