import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NavbarComponent } from 'src/app/component/navbar/navbar.component';
import { TiktokModel } from 'src/app/models/tiktokModel.model';
import { TiktokService } from 'src/app/services/tiktok.service';
import {Router} from "@angular/router";
import {UploadService} from "../../../services/upload.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {QuillModule} from "ngx-quill";

@Component({
  standalone: true,
  imports: [
    NavbarComponent,
    FormsModule,
    NgIf,
    QuillModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule
  ],
  selector: 'app-tiktok-create',
  templateUrl: './tiktok-create.component.html',
  styleUrls: ['./tiktok-create.component.scss']
})
export class TiktokCreateComponent {

  post: TiktokModel = {
    description: '',
    image: '',
    toggleValue: false,
    is_display: 0,
    video_url: '',
    title: '',
    id: 0
  };
  isLoading = false;
  selectedFile!: File;
  fileName = '';
  @Input()
  requiredFileType: string = '';

  toggleValue = false;
  constructor(
    private tiktokService: TiktokService,
    private router: Router,
    private uploadService: UploadService,
  ) {

  }

  onFileSelected(event: any) {
    this.isLoading = true;
    const file:File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);
      this.uploadService.uploadImageToDrive(formData).subscribe((res: any) => {
        this.post.image = res.imageUrl;
        this.isLoading = false;
      })
    }
  }

  submitForm(): void {
    if (this.toggleValue) {
      this.post.is_display = 1;
    } else {
      this.post.is_display = 0;
    }
    this.tiktokService.createTiktok(this.post).subscribe((res: any) => {
      if(res.success) {
        this.router.navigate(['/admin/home']);
      }
    });
  }
}
