<div class="container mx-auto pb-8">
    <section class="bg-white py-10 px-4">
        <div class="text-center font-semibold text-[32px] mb-8 leading-9">Return and Exchange Policy at Anbi Hair</div>
        <div class="text-lg text-gray-800 mb-4">update 01/01/2023</div>

        <div class="text-gray-700">At Anbi Hair, we strive to ensure your satisfaction with our products, and we are pleased to offer an easy and transparent return and exchange policy. To initiate an exchange, please adhere to the following guidelines:
        </div>
        <h3 class="text-2xl font-semibold text-[#ff6501]">1. Condition of Products:</h3>
        <ol class="list-disc mb-8 ml-4">
            <li class="text-gray-700">Products must be new, unworn, and unused.</li>
            <li class="text-gray-700"> Products must not be washed.</li>
            <li class="text-gray-700">Products must be attached with all labels intact.</li>
        </ol>
        <h3 class="text-2xl font-semibold text-[#ff6501]">2. Timeframe for Exchange and Return:</h3>
        <ol class="list-disc mb-8 ml-4">
            <li class="text-gray-700">Clients have 07 days from the date of receiving the package to request an exchange or return.</li>
        </ol>
        <h3 class="text-2xl font-semibold text-[#ff6501]">3. Items not eligible for exchange/return:</h3>
        <ol class="list-disc mb-8 ml-4">
            <li class="text-gray-700">Orders with customized textures or colors based on photos provided by clients.</li>
        </ol>
        <h3 class="text-2xl font-semibold text-[#ff6501]">4. Return Process to Anbi Hair Warehouse:</h3>
        <ol class="list-disc mb-8 ml-4">
            <li class="text-gray-700">Refund conditions apply as per the above information. However, for returns, please follow these additional steps:</li>
            <li class="text-gray-700">Shipping costs and other costs incurred will be borne by the clients (If the error is caused by ANBI, we will support the agreement with the customer according to the prescribed internal policy).</li>
            <li class="text-gray-700">Please follow the instructions on address and shipping unit given by our CS.</li>
            <li class="text-gray-700">For returned orders with applied coupons and promotions, the final refund value will be adjusted to account for the deducted coupon or promotion value.</li>
        </ol>
        <h3 class="text-2xl font-semibold text-[#ff6501]">5. Refund Process:</h3>
        <ol class="list-disc mb-8 ml-4">
            <li class="text-gray-700">Once we receive the returned parcel and check if it meet our criteria, we will promptly contact you to determine the most convenient way to process the refund or exchange. Please allow 30 days for the refund process to be completed.</li>
        </ol>
        <div class="text-gray-700">Thank you for choosing Anbi Hair. We appreciate your understanding and cooperation in adhering to our return and exchange policy. If you have any further inquiries, please do not hesitate to contact our customer support team.</div>
    </section>
</div>