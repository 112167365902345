import { Component } from '@angular/core';
import {SeoService} from "../services/seo.service";

@Component({
  standalone: true,
  imports: [],
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  constructor(
    private seo: SeoService
  ) {
    this.seo.generateTags({
      title: 'Contact Page',
      description: 'VN hair extensions factory.  Anbi is a full-cycle hair factory that starts with the purchase of donor hair and extensions. ',
      slug: 'contact'
    })
  }
  public goTo(type: number, link: any): void {
    if (type === 1) {
      const phoneNumber = link;
      const message = 'Hi';
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank');
      return;
    }
    window.open(link, '_blank');
  }
}
