import { Injectable } from '@angular/core';
import { UserModel } from '../models/userModel.model';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { InstagramModel } from '../models/instagramModel.model';
import { UploadService } from './upload.service';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  protected apiServerPaths = environment.apiServer.paths.instagrams;
  constructor(
    private apiService: ApiService,
  ) { }

  public getAllPost(): Observable<InstagramModel[]> {
    return this.apiService.get(this.apiServerPaths.list, {}, map(response => {
      return response;
    }));
  }

  public getPostById(id: number): Observable<InstagramModel> {
    return this.apiService.get(this.apiServerPaths.list + '/' + id, {}, map(response => {
      return response;
    }));
  }

  public getInstagramInfo(id: string): Observable<any> {
    return this.apiService.get(this.apiServerPaths.info + '/' + id, {}, map(response => {
      return response;
    }));
  }

  public updateInstagram(id: number, data: any): Observable<any> {
    return this.apiService.put(this.apiServerPaths.list + '/' + id, data, {}, map(response => {
      return response;
    }));
  }

  public createInstagram(data: any): Observable<any> {
    return this.apiService.post(this.apiServerPaths.create, data, {}, map(response => {
      return response;
    }));
  }
}
