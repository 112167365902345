export const MENU = [
  {link: '/home', key: 'home', label: 'Home'},
  {link: '/products', key: 'product', label: 'Product', childrens: [
      {link: '/products', key: 'machineWeftHair', label: 'Machine Weft Hair'},
      {link: '/products', key: 'closure', label: 'Closure & Frontal'},
      {link: '/products', key: 'wig', label: 'Wigs'},
      {link: '/products', key: 'ext', label: 'Hair Extentions'},
      {link: '/products', key: 'color', label: 'Color Chart & Texture'},
    ]},
  {link: '/blogs', key: 'contact', label: 'Blog'},
  {link: '/contact', key: 'contact', label: 'Contact'},
  {link: '/faq', key: 'faq', label: 'FAQ'},
  {link: '/policy', key: 'policy', label: 'Return and Exchange'},
]

