import { CanActivateFn } from '@angular/router';

export const guardNameGuard: CanActivateFn = (route, state) => {
  const storedData = localStorage.getItem('userData');
  if (storedData) {
    return true
  }
  window.location.href = '/auth/login';
  return false;
};
