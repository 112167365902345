import { Component, Input } from '@angular/core';
import { NavbarComponent } from '../../../component/navbar/navbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { InstagramService } from '../../../services/instagram.service';
import { InstagramModel } from '../../../models/instagramModel.model';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { Subscription, finalize } from 'rxjs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {UploadService} from "../../../services/upload.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {QuillModule} from "ngx-quill";
@Component({
  standalone: true,
  imports: [
    NavbarComponent,
    FormsModule,
    NgIf,
    QuillModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule
  ],
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent {
  isLoading = false;
  private routeSub: Subscription;
  postId: number = 0;
  post: InstagramModel = {
    description: '',
    id: 0,
    image: '',
    toggleValue: false,
    imageObj: {},
    is_display: 0,
    likes: '0',
    view: '0',
    comment:'0',
    url: '',
    short_description: ''
  }
  fileName = '';
  @Input()
  requiredFileType: string = '';

  toggleValue = false;
  constructor(
    private route: ActivatedRoute,
    private instagramService: InstagramService,
    private uploadService: UploadService,
    private router: Router,
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.postId = params['id']
    });
  }

  ngOnInit(): void {
    this.getPostById(this.postId)
  }


  onFileSelected(event: any) {
    this.isLoading = true;
    const file:File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);
      this.uploadService.uploadImageToDrive(formData).subscribe((res: any) => {
        this.post.image = res.imageUrl;
        this.isLoading = false;
      })
    }
  }

  fetchInstagramData() {
    const parts = this.post.url.split('/');
    const instaId = parts[parts.length - 2];
    this.instagramService.getInstagramInfo(instaId).subscribe((response: any) => {
      this.post.description = response.description;
      this.post.image = response.thumbnail;
      this.post.likes = response.likesCount;
      this.post.view = response.viewCount;
      this.post.comment = response.commentsCount;
    })
  }

  getPostById(postId: number) {
    this.instagramService.getPostById(postId).subscribe((res: any) => {
      if (res.success) {
        this.post = res.data;
        if (this.post.is_display === 0) {
          this.toggleValue = false;
        }
        if (this.post.is_display === 1) {
          this.toggleValue = true;
        }
        this.fetchInstagramData()
      }
    })
  }


  submitForm(): void {
    if (this.toggleValue) {
      this.post.is_display = 1;
    }

    if (!this.toggleValue) {
      this.post.is_display = 0;
    }
    this.instagramService.updateInstagram(this.postId, this.post).subscribe((res: any) => {
      if(res.success) {
        this.router.navigate(['/admin/home']);
      }
    })
  }
}
