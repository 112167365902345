<div class="container mx-auto pb-8">
  <section class="bg-white py-10 px-4">
    <div class="text-center font-semibold text-[32px] mb-8">How to buy</div>
    <div class="flex flex-col md:flex-row gap-3">
      <div class="flex flex-col basis-auto flex-grow flex-shrink w-full md:w-1/2">
        <div class="bg-white p-8 rounded-lg shadow-lg my-4">
          <h2 class="text-3xl font-semibold text-gray-800 mb-4">Ordering with Door-to-Door Delivery</h2>

          <!-- Step 1: Contact Us -->
          <div class="mb-8">
            <h3 class="text-2xl font-semibold text-[#ff6501]">1. Contact Us</h3>
            <p class="text-gray-700 mt-2">For exclusive order placements, please connect with us via WhatsApp. This direct communication channel allows you to place orders directly from our factory, benefiting from wholesale pricing and worldwide courier delivery.</p>
          </div>

          <!-- Step 2: Provide Order Details -->
          <div class="mb-8">
            <h3 class="text-2xl font-semibold text-[#ff6501]">2. Provide Order Details</h3>
            <p class="text-gray-700 mt-2">When you reach out to us, kindly furnish us with comprehensive details regarding your order. This should include:</p>
            <ul class="list-disc list-inside mt-2 text-gray-700">
              <li>The specific product type you require (extensions, wigs, etc.).</li>
              <li>Your desired hair type (virgin, raw, baby).</li>
              <li>Preferred color (you can provide an image or description).</li>
              <li>Desired length (available from 6" to 60").</li>
              <li>Texture preference (bone straight, bouncy, deep wave, etc.).</li>
              <li>Quantity needed (e.g., 3 bundles, 1 HD lace 5x5, etc.).</li>
              <li>Any additional information relevant to your order.</li>
            </ul>
          </div>

          <!-- Step 3: Invoice Confirmation -->
          <div class="mb-8">
            <h3 class="text-2xl font-semibold text-[#ff6501]">3. Invoice Confirmation</h3>
            <p class="text-gray-700 mt-2">Based on the provided details, we will prepare an invoice and send it to you for your review. This step is crucial to ensuring accuracy in all aspects of the order.</p>
          </div>

          <!-- Step 4: Payment Options -->
          <div class="mb-8">
            <h3 class="text-2xl font-semibold text-[#ff6501]">4. Payment Options</h3>
            <p class="text-gray-700 mt-2">Choose from the following payment methods:</p>
            <ul class="list-disc list-inside mt-2 text-gray-700">
              <li><span class="text-green-600">Bank Transfer:</span> Make a bank transfer in either US dollars or Euro to our designated bank account.</li>
              <li><span class="text-green-600">Credit Card:</span> Enjoy the convenience of credit card payment. We'll provide a payment link, requiring an operational email address for payment.</li>
              <li><span class="text-green-600">Agent:</span> This option allows payment through our agent. You can easily transfer funds through them.</li>
            </ul>
          </div>

          <!-- Step 5: Product Confirmation -->
          <div class="mb-8">
            <h3 class="text-2xl font-semibold text-[#ff6501]">5. Product Confirmation</h3>
            <p class="text-gray-700 mt-2">If the requested products are available in our inventory, we'll send you a photograph for your approval prior to shipment. In cases where the products are not in stock, production will be initiated, with an estimated timeframe of 1 to 2 weeks.</p>
          </div>

          <!-- Step 6: Video Confirmation -->
          <div class="mb-8">
            <h3 class="text-2xl font-semibold text-[#ff6501]">6. Video Confirmation</h3>
            <p class="text-gray-700 mt-2">To ensure transparency, every order is documented through video confirmation before it's dispatched.</p>
          </div>

          <!-- Step 7: Shipping -->
          <div class="mb-8">
            <h3 class="text-2xl font-semibold text-[#ff6501]">7. Shipping</h3>
            <p class="text-gray-700 mt-2">We partner with reputable courier services such as FedEx, DHL, and UPS for shipping. Shipping costs depend on weight and destination. For instance, shipping to Europe or the USA begins at $60 for the initial 0.5 kilo, and around $150 for 5 kilos. Shipping fees vary across different regions. Specific countries, like Nigeria, Ghana, and Cameroon, offer agent-assisted shipping, starting from $10 for the first 0.5 kilo and roughly $20 for 5 kilos. To get precise shipping costs for your country, kindly initiate contact with us.</p>
          </div>

          <!-- Closing Statement -->
          <div>
            <p class="text-gray-700 mt-4">By following these simple steps, you can seamlessly place an order for our products and enjoy the convenience of door-to-door delivery. Should you have any queries or require assistance, don't hesitate to connect with us through WhatsApp. We're eagerly looking forward to the opportunity to cater to your needs!</p>
          </div>
        </div>

      </div>
      <div class="basis-auto flex-grow-0 flex-shrink w-full md:w-1/2">
        <img src="assets/images/payment.jpg" alt="payment image" />
      </div>
    </div>
    <div class="text-center font-semibold text-[32px] mt-8 mb-8">Contact us</div>
    <div class="flex flex-col md:flex-row gap-3">
      <div class="flex flex-col basis-auto flex-grow flex-shrink w-full md:w-1/2">
        <div class="flex flex-row justify-left items-start">
          <img width="25" class="mr-4" src="assets/images/icons/location-brown.svg" />
          <div class="flex flex-col">
            <h2 class="text-xl">LOCATION</h2>
            <p class="text-base">Anbi hair factory, Dong Xuat, Dong Tho, Yen Phong, Bac Ninh</p>
          </div>
        </div>
        <div class="flex flex-row justify-left items-start  ">
          <img width="25" class="mr-4" src="assets/images/icons/clock.svg" />
          <div class="flex flex-col">
            <p class="text-xl">OPEN HOURS</p>
            <p class="text-base">Monday - Friday</p>
            <p class="text-base">9.00 a.m. – 5.00 p.m.</p>
            <p class="text-base">Saturday and Sunday : days off</p>
          </div>
        </div>
        <div class="flex flex-row justify-left items-start  ">
          <img width="25" class="mr-4" src="assets/images/icons/mail.svg" />
          <div class="flex flex-row gap-3">
            <p class="text-base mr-4">Email: </p>
            <p class="text-base">
              <span class="text-[#bc8640]"><a title="email to mr anbi" href="mailto:anbihair@gmail.com" class="text-[#bc8640]">anbihair@gmail.com</a></span>
            </p>
          </div>
        </div>
        <div class="flex flex-row justify-left items-start  ">
          <img width="25" class="mr-4" src="assets/images/icons/phone-brown.svg" />
          <div class="flex flex-row gap-3">
            <p class="text-base mr-4">Phone: </p>
            <p class="text-base"><span class="underline text-[#BC8640]"><a href="tel:+84 964197276">+84 964197276</a></span> / <span class="underline text-[#BC8640]"><a href="tel:+84 333284471">+84 333284471</a></span></p>
          </div>
        </div>
        <div class="flex flex-row justify-left items-start  ">
          <img width="25" class="mr-4" src="assets/images/icons/wapps.svg" />
          <div class="flex flex-row gap-3">
            <p class="text-base mr-4">WhatsApp:</p>
            <p class="text-base">
              <span class="cursor-pointer underline text-[#BC8640]" (click)="goTo(1, '84964197276')">+84 964197276</span>
              /<span class="cursor-pointer underline text-[#BC8640]" (click)="goTo(1, '84333284471')">+84 333284471</span>
            </p>
          </div>
        </div>
      </div>
      <div class="basis-auto flex-grow-0 flex-shrink w-full md:w-1/2">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.6071043658!2d105.95054737613384!3d21.168028580516385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135070d60b3434f%3A0xc0f06256a17af3a0!2sANBI%20Hair%20Factory!5e0!3m2!1svi!2s!4v1695276175983!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </section>
</div>
