import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BlogService} from '../../services/blog.service';
import {Subscription} from 'rxjs';
import {BlogModel} from '../../models/blogModel.model';
import {FormsModule} from '@angular/forms';
import {NavbarComponent} from '../../component/navbar/navbar.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgIf} from '@angular/common';
import {UploadService} from "../../services/upload.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {QuillModule} from 'ngx-quill';
import { QuillEditorComponent } from 'ngx-quill';
@Component({
  standalone: true,
  imports: [
    FormsModule,
    NavbarComponent,
    MatSlideToggleModule,
    QuillModule,
    MatProgressSpinnerModule,
    NgIf
  ],
  selector: 'app-blog-admin-detail',
  templateUrl: './blog-admin-detail.component.html',
  styleUrls: ['./blog-admin-detail.component.scss']
})
export class BlogAdminDetailComponent implements OnInit {
  private routeSub: Subscription;
  quill: any;
  postId: number = 0;
  isLoading = false;
  post: BlogModel = {
    content: '',
    created_at: '',
    id: 0,
    image: '',
    is_display: 0,
    short_description: '',
    tags_id: '',
    title: '',
    toggleValue: false,
    user_id: ''
  }
  fileName = '';
  @Input()
  requiredFileType: string = '';
  toggleValue = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private blogService: BlogService,
    private uploadService: UploadService,
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.postId = params['id']
      this.getPostById(this.postId)
    });
  }

  ngOnInit() {
  }

  onFileSelected(event: any) {
    this.isLoading = true;
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);
      this.uploadService.uploadImageToDrive(formData).subscribe((res: any) => {
        this.post.image = res.imageUrl
        this.isLoading = false;
      })
    }
  }

  getPostById(postId: number) {
    this.blogService.getBlogById(postId).subscribe((res: any) => {
      if (res.success) {
        this.post = res.data;
        if (this.post.is_display === 0) {
          this.toggleValue = false;
        }
        if (this.post.is_display === 1) {
          this.toggleValue = true;
        }
      }
    })
  }

  submitForm(): void {
    this.isLoading = true;
    if (this.toggleValue) {
      this.post.is_display = 1;
    }

    if (!this.toggleValue) {
      this.post.is_display = 0;
    }
    this.blogService.updateBlog(this.postId, this.post).subscribe((res: any) => {
      if (res.success) {
        this.router.navigate(['/admin/blog']);
        this.isLoading = false;
      }
    },
    (error) => {
      if (error.status === 413) {
        // Xử lý thông báo lỗi khi mã lỗi HTTP là 413 (Payload Too Large)
        console.error('Payload Too Large Error:', error);
        // Hiển thị thông báo cho người dùng hoặc thực hiện các xử lý khác tùy theo yêu cầu
        window.alert('Image size to large')
      } else {
        // Xử lý các lỗi khác
        console.error('Other Error:', error);
        // Hiển thị thông báo cho người dùng hoặc thực hiện các xử lý khác tùy theo yêu cầu
      }
      this.isLoading = false;
    })
  }
}
