import { Component } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { UserModel } from '../../models/userModel.model';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  standalone: true,
  imports: [
    FormsModule
  ],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loginData: UserModel = {email: '', id: 0, pwd: ''}
  constructor(
    private loginService: LoginService,
    private router: Router
  ) {
  }

  login(): void {
    this.loginService.login(this.loginData).subscribe((res: any) => {
      if (res.success) {
        localStorage.setItem("userData", JSON.stringify(res.user));
        this.router.navigate(['/admin/home']);
      }
    })
  }
}
