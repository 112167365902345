import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';
import { NavbarComponent } from 'src/app/component/navbar/navbar.component';
import { InstagramModel } from 'src/app/models/instagramModel.model';
import { InstagramService } from 'src/app/services/instagram.service';
import {Router} from "@angular/router";
import {UploadService} from "../../../services/upload.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {QuillModule} from "ngx-quill";

@Component({
  standalone: true,
  imports: [
    NavbarComponent,
    FormsModule,
    NgIf,
    QuillModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule
  ],
  selector: 'app-instagram-create',
  templateUrl: './instagram-create.component.html',
  styleUrls: ['./instagram-create.component.scss']
})
export class InstagramCreateComponent {
  post: InstagramModel = {
    description: '',
    image: '',
    toggleValue: false,
    is_display: 0,
    url: '',
    likes: '0',
    view: '0',
    comment:'0',
    short_description: '',
    id: 0,
    imageObj: {}
  };
  fileName = '';
  @Input()
  requiredFileType: string = '';
  isLoading = false;
  toggleValue = false;
  constructor(
    private instagramService: InstagramService,
    private router: Router,
    private uploadService: UploadService
  ) {

  }

  onFileSelected(event: any) {
    this.isLoading = true;
    const file:File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);
      this.uploadService.uploadImageToDrive(formData).subscribe((res: any) => {
        this.post.image = res.imageUrl;
        this.isLoading = false;
      })
    }
  }

  fetchInstagramData() {
    const parts = this.post.url.split('/');
    const instaId = parts[parts.length - 2];
    this.instagramService.getInstagramInfo(instaId).subscribe((response: any) => {
      this.post.description = response.description;
      this.post.image = response.thumbnail;
      this.post.likes = response.likesCount;
      this.post.view = response.viewCount;
      this.post.comment = response.commentsCount;
    })
  }

  submitForm(): void {
    if (this.toggleValue) {
      this.post.is_display = 1;
    } else {
      this.post.is_display = 0;
    }
    this.instagramService.createInstagram(this.post).subscribe((res: any) => {
      if(res.success) {
        this.router.navigate(['/admin/home']);
      }
    });
  }

}
