import {Component} from '@angular/core';
import {NavbarComponent} from "../component/navbar/navbar.component";
import {NgForOf, NgIf} from "@angular/common";
import {InstagramModel} from "../models/instagramModel.model";
import {TiktokModel} from "../models/tiktokModel.model";
import {MENU} from "../share/constant";
import {CarouselModule, OwlOptions} from 'ngx-owl-carousel-o';
import {InstagramService} from "../services/instagram.service";
import {TiktokService} from "../services/tiktok.service";
import {YoutubeService} from "../services/youtube.service"
import { RouterLink } from '@angular/router';
import { YoutubeModel } from '../models/youtubeModel.model';
import {SeoService} from "../services/seo.service";
import {ProductsComponent} from "../products/products.component";
import { Observable, forkJoin, map, mergeMap } from 'rxjs';
@Component({
  standalone: true,
  imports:
    [
      NavbarComponent,
      NgForOf,
      NgIf,
      CarouselModule,
      RouterLink,
      ProductsComponent
    ],
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  listInstagramPost: InstagramModel[] = []
  listInstagramPostOriginal: InstagramModel[] = []
  listTiktokPost: TiktokModel[] = []
  listYoutubePost: YoutubeModel[] = []
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<<', '>>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  customOptions2: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 3000,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<<', '>>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  constructor(
    private instagramService: InstagramService,
    // private tiktokService: TiktokService,
    // private youtubeService: YoutubeService,
    private seo: SeoService
  ) {
    this.seo.generateTags({
      title: 'ANBIHAIR',
      description: 'VN hair extensions factory.  Anbi is a full-cycle hair factory that starts with the purchase of donor hair and extensions. ',
      slug: ''
    })
    this.getAllInstagramPost();
    // this.getAllVideoTiktok();
    // this.getAllVideoYoutube();
  }

  getAllInstagramPost(): void {
    this.instagramService.getAllPost()
      .pipe(
        mergeMap((res: any) => {
          if (res.success) {
            this.listInstagramPost = res.data;
            this.listInstagramPost = this.listInstagramPost.filter((item: InstagramModel) => item.is_display === 1);
            this.listInstagramPostOriginal = this.listInstagramPost
            const requests = this.listInstagramPost.map((item: InstagramModel) => {
              const parts = item.url.split('/');
              const instaId = parts[parts.length - 2];
              return this.instagramService.getInstagramInfo(instaId);
            });

            return forkJoin(requests);
          } else {
            return [];
          }
        })
      )
      .subscribe((responses: any[]) => {
        responses.forEach((response: any, index: number) => {
          const item = response;
          item.url = this.listInstagramPostOriginal[index].url;
          item.description = response.description;
          item.image = response.thumbnail;
          item.likes = response.likesCount;
          item.view = response.viewCount;
          item.comment = response.commentsCount;
          this.listInstagramPost[index] = item
        });
      });
  }
  // getAllVideoTiktok(): void {
  //   this.tiktokService.getAllPost().subscribe((res: any) => {
  //     if (res.success) {
  //       this.listTiktokPost = res.data
  //       this.listTiktokPost = this.listTiktokPost.filter((item: TiktokModel) => item.is_display === 1)
  //     }
  //   })
  // }
  // getAllVideoYoutube(): void {
  //   this.youtubeService.getAllPost().subscribe((res: any) => {
  //     if (res.success) {
  //       this.listYoutubePost = res.data
  //       this.listYoutubePost = this.listYoutubePost.filter((item: YoutubeModel) => item.is_display === 1)
  //     }
  //   })
  // }

  updateInsgram(): void {

  }
}
